import React, { Component } from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as winter22 from './menus/w22.json';
import * as spring22 from './menus/sp22.json';
import * as summer22 from './menus/su22.json';
import * as winter23 from './menus/w23.json';
import * as summer23 from './menus/su23.json';
import * as winter24 from './menus/w24.json';
import * as spring24 from './menus/sp24.json';
import * as winter25 from './menus/w25.json';
import './index.css';
import highball from './icons/highball.png';
import lowball from './icons/lowball.png';
import martini from './icons/martini.png';
import wine from './icons/wine.png';
import coupe from './icons/coupe.png';
import shot from './icons/shot.png';
import unknown from './icons/unknown.png';
import yilanIcon from './yilan_icon.png';
import headerw23 from './media/w23_header.png';
import headersu23 from './media/su23_header.png';
import headersp23 from './media/sp23_header.png';
import breakw23 from './media/w23_break.png';
import breaksu23 from './media/su23_break.png';
import breaksp23 from './media/sp23_break.png';

const currentSeasonKey = 'w25'

const pictures = {
  highball: highball,
  lowball: lowball,
  martini: martini,
  wine: wine,
  coupe: coupe,
  shot: shot,
  unknown: unknown,
}

const menuConversions = {
  'w22': winter22,
  'sp22': spring22,
  'su22': summer22,
  'w23': winter23,
  'su23': summer23,
  'w24': winter24,
  'sp24': spring24,
  'w25': winter25,
}

const breakPicsBySeason = {
  Spring: breaksp23,
  Summer: breaksu23,
  Fall: breakw23,
  Winter: breakw23,
}

const headerPicsBySeason = {
  Spring: headersp23,
  Summer: headersu23,
  Fall: headerw23,
  Winter: headerw23,
}

const colors = {
  border: {
    Spring: '#B5E646',
    Summer: '#497FB0',
    Fall: '#BF0000',
    Winter: '#D17F7F',
  }
}

class MenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.data.name,
      ingredients: this.props.data.ingredients,
      quip: this.props.data.quip,
      format: this.props.data.format,
      alignLeft: this.props.alignLeft === undefined ? true : this.props.alignLeft
    }
  }

  render() {
    if (this.state.alignLeft) {
      return (
        <Container className="drink">
          <Row>
            <Col xs={10} md={8} lg={6}>
              <h4 className="name" style={{ whiteSpace: "pre" }}>{this.state.name}</h4>
            </Col>
            <Col xs={2}>
              <img src={pictures[this.state.format]} alt={this.state.format} className="drink-pic" />
            </Col>
          </Row>
          <div className="quip">
            <p className="quip-text">“ {this.state.quip} ”</p>
          </div>
          <p className="ingredients-text">{this.state.ingredients}</p>
        </Container>
      );
    } else {
      return (
        <Container className="drink">
          <Row>
            <Col xs={2}>
              <img src={pictures[this.state.format]} alt={this.state.format} className="drink-pic" />
            </Col>
            <Col style={{justifyContent: "right", display: "flex"}}>
              <h4 className="name" style={{paddingRight: "5px", whiteSpace: "pre", textAlign: "end"}}>{this.state.name}</h4>
            </Col>
          </Row>
          <div className="quip" style={{justifyContent: "right", display: "flex"}}>
            <p className="quip-text" style={{textAlign: "end"}}>“ {this.state.quip} ”</p>
          </div>
          <p className="ingredients-text" style={
            {justifyContent: "right", display: "flex", paddingRight: "5px"}
          }>{this.state.ingredients}</p>
        </Container>
      )
    }
  }
}

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      drinks: this.props.drinks,
      titleAlignLeft: this.props.titleAlignLeft === undefined ? true : this.props.titleAlignLeft,
      breakPic: this.props.break,
    }
  }

  // For some reason modularizing the pieces makes all the children not align
  // right, so have to return the whole thing as a big if/else clause. Should
  // probably fix this but also so much work eh...
  render() {
    if (this.state.titleAlignLeft) {
      return (
        <Container className="section">
          <Row className="section-row-col">
            <Col xs="6" className="section-row-col">
              <p className="section-head">{this.state.title}</p>
            </Col>
            <Col className="section-line-box">
              <hr className="section-line"/>
            </Col>
          </Row>
          {this.state.drinks.map(drink => {
            return (
              <MenuItem data={drink} key={drink.name} alignLeft={this.state.titleAlignLeft}/>
            )
          })}
          {this.state.breakPic !== null &&
          <img src={this.state.breakPic} alt='fun pic'></img> }
        </Container>
      )
    } else {
      return (
        <Container className="section">
          <Row className="section-row-col">
            <Col xs="6" className="section-line-box">
              <hr className="section-line"/>
            </Col>
            <Col xs="6" className="section-row-col" style={{justifyContent: "flex-end"}}>
              <p className="section-head" style={{textAlign: "right", display: "flex", flexDirection: "row-reverse"}}>{this.state.title}</p>
            </Col>
          </Row>
          {this.state.drinks.map(drink => {
            return (
              <MenuItem data={drink} key={drink.name} alignLeft={this.state.titleAlignLeft}/>
            )
          })}
        </Container>
      )
    }
  }
}

class SeasonHistory extends Component {
  constructor(props) {
    super(props);
    const seasons = Object.keys(menuConversions).filter((item) => item !== props.currentSeason)
    this.state = {
      seasons: seasons,
    }
  }

  convertSeasonName(seasonRaw) {
    const season = seasonRaw.match(/^[a-z]+/)
    const year = seasonRaw.match(/[0-9]+$/)
    const seasonConvert = {'sp': 'Spring', 'su': 'Summer', 'f': 'Fall', 'w': 'Winter'}
    return `${seasonConvert[season]} 20${year}`
  }

  render() {
    return (
      <Container>
        {this.state.seasons.reverse().map(
          (season) => {
            return (
              <Row style={{justifyContent: "right", display: "flex"}}>
                <a href={`bar-${season}`} style={{color: "black"}}>{this.convertSeasonName(season)}</a>
              </Row>
            )
          }
          )}
      </Container>
    )
  }
}


export default class BarMenu extends Component {
  constructor(props) {
    super(props);
    const path = window.location.pathname;
    const seasonRaw = path.split("-", 2)[1];
    const season = seasonRaw === 'menu' ? currentSeasonKey : seasonRaw;
    const data = menuConversions[season];
    this.updateHistory = this.updateHistory.bind(this)

    if (data === undefined) {
      this.state = {
        season: "I think you're lost",
        seasonCode: season,
        readableSeason: 'Summer',
        barData: {},
        breakPic: null,
        headerPic: headersu23,
        showHistory: true,
      }
    }
    else {
      const readableSeason = data.default.season.split(' ')[0]
      this.state = {
        season: data.default.season,
        seasonCode: season,
        readableSeason: readableSeason,
        barData: data.default.menu,
        // not every season has break pics
        breakPic: breakPicsBySeason[readableSeason] || null,
        headerPic: headerPicsBySeason[readableSeason] || null,
        showHistory: false,
      }
    }
  }

  componentDidMount() {
    document.title = this.state.season; 
  }

  updateHistory() {
    const currentShow = this.state.showHistory;
    this.setState({
      showHistory: !currentShow,
    });
  }
  
  render() {
    return (
      <Container className="menu" style={{borderColor: colors.border[this.state.seasonCode] || colors.border[this.state.readableSeason]}}>
        <div className='season-box'>
          <span 
            className='season'
            onClick={this.updateHistory}
          >{this.state.season}</span>
        </div>
        {this.state.showHistory ? (
          <div className='season-box' id="menu-history">
            <SeasonHistory currentSeason={this.state.seasonCode}></SeasonHistory>
          </div>
        ) : null}
        <div className="title">
          <img src={this.state.headerPic} alt="beau bar"></img>
        </div>
        
        {Object.entries(this.state.barData).map(([sectionTitle, drinks], index) => {
          return (
            <Section 
              title={sectionTitle} 
              drinks={drinks} 
              key={sectionTitle} 
              titleAlignLeft={index % 2 === 0} 
              break={index === 0 ? this.state.breakPic: null}
            />
          )
        })}
        <div className="title">
          <img src={yilanIcon} alt="logo"></img>
        </div>
      </Container>
    )
  }
}
